import React, { useEffect, useState } from "react";

import FormLabel from "./form-label";
import FormGroup from "./form-group";
import LayoutWolfbergs from "./layout";
import SectionWolfbergs from "./section";
import { BASE_URL } from "../constant";
import { callApi } from "../common/api";
import { FullScreenLoader } from "../common/Spinner";
import uuid from "react-uuid";
import "./index.css";
import { validateForm } from "./validate-form";
import QuestionGroup from "./question-group";
import LayoutGroup from "./question-group/layout-group";
import { errorToast, successToast } from "../common/toast";
import { useNavigate } from "react-router-dom";

export default function WolfbergQuestionaire({
  _questions,
  _dispayType,
  _title,
  _description,
}) {
  const [questions, setQuestions] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sectionsCompleted, setSectionsCompleted] = useState({});
  const [fileBase64, setFileBase64] = useState("")
  const [displayType, setDisplayType] = useState("list");

  const [title, setTitle] = useState("Questionaire");

  const [description, setDescription] =
    useState(`Lorem Ipsum is simply dummy text of the printing and typesetting
  industry. Lorem Ipsum has been the industry's standard dummy text
  ever since the 1500s, when an unknown printer took a galley of
  type .`);

  const navigate = useNavigate();

  useEffect(() => {
    initializ();
  }, []);

  const initializ = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    setToken(urlParams);
    let data = {};
    if (_questions) {
      data = _questions;
      setDisplayType(_dispayType);
      setTitle(_title);
      setDescription(_description);
    } else {
      const response = await fetchData(urlParams);
      if (!response) return;
      console.log("fewf", response.data)
      data = response.data;
      setDisplayType(data.displayType);
      setTitle(data.title);
      setDescription(data.description);
      if (data.fileBase64) {
        setFileBase64(data.fileBase64)
      }
    }

    const updatedData = removeYnusableKey(data.data);
    const questionsWithIdAdded = addIdToQuestions(updatedData);
    setQuestions(questionsWithIdAdded);

    setLoading(false);
  };

  console.log("titt", title, description)
  const addIdToQuestions = (data) => {
    const updated = {};
    Object.keys(data).forEach((key) => {
      updated[key] = data[key].map((item) => {
        return {
          ...item,
          id: uuid(),
          invalid: false,
          options: Array.isArray(item?.options)
            ? item.options.map((option) => ({ ...option, id: uuid() }))
            : [],
          innerQuestions: item.innerQuestions.map((innerQuestion) => ({
            ...innerQuestion,
            id: uuid(),
            invalid: false,
            options: Array.isArray(innerQuestion?.options)
              ? innerQuestion.options.map((option) => ({
                  ...option,
                  id: uuid(),
                }))
              : [],
          })),
        };
      });
    });
    return updated;
  };

  const setToken = (urlParams) => {
    const token = urlParams.get("token");
    localStorage.setItem(
      "expiry",
      new Date(new Date().getTime() + 24 * 60 * 60 * 1000).valueOf()
    );
    localStorage.setItem("jwt_ahrvo", token);
    
  };

  const removeYnusableKey = (response) => {
    let updated = {};
    Object.keys(response).filter((key) => {
      if (key != "_id") {
        updated[key] = response[key]
          .filter((item) => item != null)
          .map((item) => {
            item.innerQuestions = item.innerQuestions.map((innerItem) => {
              return { ...innerItem, value: "" };
            });
            return { ...item, value: "" };
          });
      }
    });
    return updated;
  };
  const fetchData = async (urlParams) => {
    try {
      let url = BASE_URL + "questionaire-by-id";
      
      let response = await callApi(
          "get",
          "questionaire-by-id",
          {},
          false
        );
     
      if (response?.message) {
        errorToast(response.message || "An error occurred while getting data");

        return;
      }

      return response;
    } catch (error) {
      console.log("fewfew", error);

      errorToast("An error occurred while getting data");
    }
    return null;
  };

  const handleChange = (keyToUpdate, indexToUpdate, value) => {
    const updatedQuestions = {};
    Object.keys(questions).forEach((key) => {
      if (key == keyToUpdate) {
        const updatedItems = questions[key].map((item, index) => {
          if (indexToUpdate == index) return { ...item, value, invalid: false };
          return { ...item };
        });

        updatedQuestions[key] = updatedItems;
      } else {
        updatedQuestions[key] = questions[key];
      }
    });
    setQuestions(updatedQuestions);
  };

  const handleInnerItemChange = (
    keyToUpdate,
    indexToUpdate,
    innerIndexToUpdate,
    value
  ) => {
    const updatedQuestions = {};
    Object.keys(questions).forEach((key) => {
      if (key == keyToUpdate) {
        const updatedItems = questions[key].map((item, index) => {
          if (indexToUpdate == index) {
            const innerQuestions = item.innerQuestions.map(
              (innerItem, innerIndex) => {
                if (innerIndex == innerIndexToUpdate) {
                  return { ...innerItem, value, invalid: false };
                }
                return { ...innerItem };
              }
            );
            return { ...item, innerQuestions };
          }
          return { ...item };
        });

        updatedQuestions[key] = updatedItems;
      } else {
        updatedQuestions[key] = questions[key];
      }
    });
    setQuestions(updatedQuestions);
  };

  const onSaveClick = async () => {
    const { isInvalid, updated } = validateForm(questions);
    setQuestions(updated);

    if (_questions) {
      successToast("Completed the form successfully");
      return;
    }
    if (isInvalid) {
      errorToast("Please complete all the questions");
      return;
    }
    try {
      setLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const business_id = urlParams.get("busines_id");
      let response = null;
      if (business_id) {
        response = await callApi(
          "post",
          "questionaire/save-answers",
          questions,
          false,
          "business_id=" + business_id
        );
      } else {
        response = await callApi(
          "post",
          "questionaire/save-answers",
          questions,
          false
        );
      }
      setLoading(false);
      if (!response.success) {
        errorToast(
          response.message ||
            response.msg ||
            "An error occurred while saving your response, please try again"
        );

        return;
      }
      successToast(
        "Your response have been saved, redirecting you to home page"
      );
      setTimeout(() => {
        navigate("/welcome");
      }, 3000);

      return response;
    } catch (error) {
      errorToast(
        "An error occurred while saving your response, please try again"
      );
    }
    setLoading(false);
  };

  if (loading) return <FullScreenLoader />;
  return (
    <>
      {displayType == "grid" ? (
        <LayoutGroup fileBase64={fileBase64} title={title} description={description}>
          <QuestionGroup
            onComplete={onSaveClick}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            questions={questions}
            sectionsCompleted={sectionsCompleted}
            setQuestions={setQuestions}
            handleChange={handleChange}
            handleInnerItemChange={handleInnerItemChange}
            setSectionsCompleted={setSectionsCompleted}
          />
        </LayoutGroup>
      ) : (
        <>
          <LayoutWolfbergs fileBase64={fileBase64} title={title} description={description}>
            {Object.keys(questions).map((key, i) => (
              <SectionWolfbergs
                showLabel={true}
                onChange={(value, index) => handleChange(key, index, value)}
                label={key}
                key={i}
                data={questions[key]}
                onInnerItemChange={(value, indexToUpdate, innerIndexToUpdate) =>
                  handleInnerItemChange(
                    key,
                    indexToUpdate,
                    innerIndexToUpdate,
                    value
                  )
                }
                index={i + 1}
              />
            ))}
            <div
              className="nk-kycfm-action pt-2"
              style={{
                marginBottom: "2rem",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <button
                onClick={onSaveClick}
                type="button"
                className="btn btn-lg btn-primary"
                id="form-button"
                value="submit"
                style={{
                  color: "#fff",
                }}
              >
                Submit
              </button>
            </div>
          </LayoutWolfbergs>
        </>
      )}
    </>
  );
}

/*




 */
